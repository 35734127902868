.link {
  display: flex;
  align-items: center;
  height: 58px;
  color: #030a1a;
  font-size: 16px;
  cursor: pointer;
}

.link > a {
  color: #030a1a;
}

.nav-hover {
  color: #1a66ff !important;
  font-weight: bold;
}

.link:not(.popover-container):hover div {
  color: #1a66ff !important;
  font-weight: bold;
  transition: all 0s 0.15s;
}

.navTitle {
  color: #030a1a;
  font-weight: 400;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 22px;
}

.navTitle:hover {
  color: #1890ff;
  cursor: pointer;
}

.popup-wrapper {
  position: absolute;
  top: 57px;
  right: 0;
  left: 0;
  z-index: 100;
}

.textin_admask {
  padding: 0;
  .adtip {
    width: 233px;
    height: 34px;
    background: linear-gradient(183deg, #fcb37e 0%, #e55245 100%);
    border-radius: 6px;
    box-shadow: inset 0 1px 12px 0 #e55245;
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#FCB37E', endColorstr='#E55245',GradientType=0 )";
    p {
      height: 31px;
      margin: 0;
      padding: 0 16px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 31px;
      white-space: nowrap;
      text-align: center;
      text-shadow: 0 2px 4px #e55245;
      span {
        width: 26px;
        height: 34px;
        margin: 0 4px;
        color: #fcd376;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        text-shadow: 0 1px 4px rgba(221, 60, 23, 0.5);
      }
    }
  }
  :global {
    .ant-popover-arrow {
      margin-top: -10px;
      .ant-popover-arrow-content {
        background: linear-gradient(183deg, #fcb37e 0%, #e55245 100%);
      }
    }
    .ant-popover-inner {
      border-radius: 6px;
      .ant-popover-inner-content {
        padding: 0;
      }
    }
    .ant-popover-placement-bottom .ant-popover-arrow {
      background: linear-gradient(183deg, #fcb37e 0%, #e55245 100%);
      box-shadow: inset 0 1px 12px 0 #e55245;
    }
    // .Navigation_link:not(.Navigation_popover-container):hover div {
    //     span {
    //         color: #d13f3f;
    //         font-weight: 100;
    //     }
    // }
  }
}

.popover-content {
  display: none;
  overflow: hidden;
  border-top: 1px solid rgb(0 0 0 / 10%);
  // border-bottom:1px solid rgb(0 0 0 / 10%) ;
  .popover-close {
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 2;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  :global {
    // 导航下拉菜单布局
    .navBox {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      min-width: 1200px;
      background: #fff;
      row-gap: 0;
      .navLeft {
        display: flex;
        flex: 1 210px;
        justify-content: flex-end;
        padding: 40px 0;
        background-color: #f2f5fa;
        background-image: url('/images/home/bg-navigation@2x.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: auto 372px;
        .leftContent {
          width: 210px;
          margin: 0 30px;
          .navLeftTitle {
            color: #030a1a;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
          }
          .navLeftDesc {
            margin: 20px 0 30px;
            color: #858c99;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      .navContent {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: space-between;
        width: 840px;
        padding: 40px;
        overflow: visible;
      }
      .navRight {
        display: flex;
        flex: 1 210px;
        justify-content: flex-end;
        padding: 40px 0;
      }
    }
  }
}

.popover-active {
  display: block;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    height: 32px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
    content: '';
  }
}

.title {
  position: relative;
  white-space: nowrap;
  &::after {
    position: absolute;
    bottom: -29px;
    left: 0;
    z-index: 101;
    padding: 6px 8px;
    color: #030a1a;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    background: linear-gradient(170deg, #e4eaf3 0%, #e0e5ef 100%);
    border: 1px solid;
    border-radius: 2px;
    border-image: linear-gradient(180deg, rgba(224, 230, 240, 0.58), rgba(255, 255, 255, 0.9)) 1 1;
    // border-image: linear-gradient(180deg, rgba(224, 230, 240, 0.58), rgba(255, 255, 255, 0.9)) 1 1;
    box-shadow: 0 4px 8px 0 rgba(3, 10, 26, 0.1), inset 0 1px 3px 0 rgba(255, 255, 255, 0.76);
    transform: translateX(-15%);
    // display: none;
    visibility: hidden;
    backdrop-filter: blur(2px);
    content: attr(data-title);
  }
  &:hover::after {
    visibility: visible;
    transition: visibility 0s 0.2s;
  }
}

.newTip {
  position: absolute;
  top: 2px;
  right: 0;
  color: #d13f3f !important;
  font-size: 10px;
  transform: scale(0.8);
}

.officialTel {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 8px;
  column-gap: 4px;
  .phone {
    color: transparent !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    background: linear-gradient(270deg, #14b6cc 0%, #1a66ff 50%, #6160f2 100%);
    background-clip: text;
  }
  :global {
    .headerPhoneIconDark,
    .headerPhoneIconLight {
      display: flex;
      align-items: center;
    }
  }
}
