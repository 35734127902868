.document-popover-wrapper {
  display: flex;
  justify-content: center;
  min-width: 1200px;
  background-color: #fff;
  .document-popover-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    .tools {
      position: relative;
      display: flex;
      background-color: #fff;
    }
  }

  .support {
    .support_item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      .desc {
        margin-left: 8px;
        color: #030a1a;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .navRight {
    display: flex;
    justify-content: flex-start !important;
    .qrcodeArea {
      .qrcodeTitle {
        margin-bottom: 16px;
        color: #000;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
      }
      .qrcodeWrapper {
        width: 240px;
        height: 136px;
        padding: 10px;
        background-image: url('/images/document/bg_qrcode.png');
        background-size: cover;
        border-radius: 8px;
        .qrcode {
          width: 116px;
          height: 116px;
        }
      }
    }
  }

  .officialTel {
    position: absolute;
    top: -40px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 8px;
    column-gap: 4px;
    .phone {
      color: transparent;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      background: linear-gradient(270deg, #14b6cc 0%, #1a66ff 50%, #6160f2 100%);
      background-clip: text;
    }
  }
}

@media (min-width: 1920px) {
  .document-popover-wrapper {
    .navRight {
      .qrcodeArea {
        padding-left: 80px;
      }
    }
  }
}
